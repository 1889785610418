import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-houston-texas.png'
import image0 from "../../images/cities/scale-model-of-union-station-in-houston-texas.png"
import image1 from "../../images/cities/scale-model-of-hermann-park-lake-plaza-in-houston-texas.png"
import image2 from "../../images/cities/scale-model-of-hawkins-sculpture-walk-in-houston-texas.png"
import image3 from "../../images/cities/scale-model-of-downtown-aquarium-in-houston-texas.png"
import image4 from "../../images/cities/scale-model-of-be-someone-in-houston-texas.png"
import image5 from "../../images/cities/scale-model-of-houston-graffiti-building-in-houston-texas.png"
import image6 from "../../images/cities/scale-model-of-houston-is-inspired-mural-in-houston-texas.png"
import image7 from "../../images/cities/scale-model-of-gerald-d.-hines-waterwall-park-in-houston-texas.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Houston'
            state='Texas'
            image={image}
            lat='29.75153'
            lon='-95.35387'
            attractions={ [{"name": "Union Station", "vicinity": "501 Crawford St, Houston", "types": ["point_of_interest", "establishment"], "lat": 29.757031, "lng": -95.35661700000003}, {"name": "Hermann Park Lake Plaza", "vicinity": "6100 Hermann Park Dr, Houston", "types": ["point_of_interest", "establishment"], "lat": 29.7164857, "lng": -95.3900706}, {"name": "HAWKINS SCULPTURE WALK", "vicinity": "5899, 5801 Caroline St, Houston", "types": ["point_of_interest", "establishment"], "lat": 29.7223213, "lng": -95.38826389999997}, {"name": "Downtown Aquarium", "vicinity": "410 Bagby St, Houston", "types": ["aquarium", "amusement_park", "restaurant", "food", "point_of_interest", "establishment"], "lat": 29.7642703, "lng": -95.36737920000002}, {"name": "Be Someone", "vicinity": "Houston", "types": ["point_of_interest", "establishment"], "lat": 29.7724227, "lng": -95.36702780000002}, {"name": "Houston Graffiti Building", "vicinity": "1503 Chartres St, Houston", "types": ["point_of_interest", "establishment"], "lat": 29.747531, "lng": -95.35901560000002}, {"name": "Houston Is Inspired Mural", "vicinity": "313 Travis St, Houston", "types": ["point_of_interest", "establishment"], "lat": 29.7620667, "lng": -95.36186609999999}, {"name": "Gerald D. Hines Waterwall Park", "vicinity": "2800 Post Oak Blvd, Houston", "types": ["park", "point_of_interest", "establishment"], "lat": 29.736339, "lng": -95.46128590000001}] }
            attractionImages={ {"Union Station":image0,"Hermann Park Lake Plaza":image1,"HAWKINS SCULPTURE WALK":image2,"Downtown Aquarium":image3,"Be Someone":image4,"Houston Graffiti Building":image5,"Houston Is Inspired Mural":image6,"Gerald D. Hines Waterwall Park":image7,} }
       />);
  }
}